














































import { Component, Watch, Vue } from "vue-property-decorator";
import { Items } from "@/types";
import { namespace } from "vuex-class";
const FiltersModule = namespace("FiltersModule");
@Component
export default class FilterItem extends Vue {
  @Watch("search")
  async onSearchChanged(query: string) {
    // Items have already been requested
    if (this.loading) return;
    this.searchItem(query);
  }
  //
  // COMPUTED
  @FiltersModule.State
  readonly items!: Items;
  get model() {
    return this.$store.state.FiltersModule.filters.itemIds;
  }
  set model(payload: string) {
    this.$store.commit("FiltersModule/FILTER_ITEM", payload);
    this.search = null;
    this.$emit("filter");
  }
  //
  // DATA
  private loading = false;
  private search = null;
  //
  // METHODS
  searchItem(query: string) {
    this.loading = true;
    setTimeout(async () => {
      await this.$store.dispatch("FiltersModule/fetchItems", query);
      this.loading = false;
    });
  }
  //
  // HOOKS
  created() {
    this.searchItem("");
  }
}
